import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const BorrowCapacityItem = ({ title = "", subTitle = "", value = "" }) => {
  return (
    <div
      className={classNames("scenario-detail-item", {
        "bc-empty": !title,
      })}
    >
      <div className="scenario-detail-item__title">{title}</div>
      {subTitle && (
        <div className="scenario-detail-item__sub-title">{subTitle}</div>
      )}
      <div className="scenario-detail-item__value">{value}</div>
    </div>
  );
};

BorrowCapacityItem.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  value: PropTypes.string,
};

export default BorrowCapacityItem;
