import maxBy from "lodash/maxBy";
import sum from "lodash/sum";
import uniqBy from "lodash/uniqBy";
import React from "react";
import { LoadingBackdrop } from "../../../../components/loading-backdrop";
import { useLastXMonthsIncomesExpensesSummary } from "../../../../hooks/account.hooks";
import { convertToInt } from "../../../../utils/convert-number";
import { formatNumberWithDollar } from "../../../../utils/numberFormater";
import BorrowCapacityItem from "./borrow-capacity-item";

const calculateMonthlyRepayment = (repaymentList) => {
  const [monthlyRepayment = 0] = repaymentList;
  return convertToInt(monthlyRepayment);
};

const getAvgBCAmountOfOneMonth = (borrowCapacityList = []) => {
  if (borrowCapacityList) {
    const numberOfMaxAmountList = 5;
    const sumOfMaxAmount = uniqBy(borrowCapacityList, "lenderId")
      .sort((a, b) => {
        return b.maxBuyingPower - a.maxBuyingPower;
      })
      .slice(0, numberOfMaxAmountList)
      .reduce((resolve, init, index, array) => {
        const { maxBorrowCapacity } = init;
        if (array.length === 0) return resolve;
        return resolve + (maxBorrowCapacity * 1.0) / array.length;
      }, 0);
    return sumOfMaxAmount;
  }
  return undefined;
};

const BorrowCapacityItemGroup = ({ isLoading = false, productList = [] }) => {
  const numberOfMonthsHavePassed = new Date().getMonth();
  const {
    data: thisYearIncomesExpensesSummaryByMonth,
  } = useLastXMonthsIncomesExpensesSummary({
    numberOfMonths: numberOfMonthsHavePassed,
    numberOfLookbackMonths: 3,
  });
  const expensesSummaryByMonth =
    thisYearIncomesExpensesSummaryByMonth?.expenses || {};

  const last3MonthsExpenses = Object.entries(expensesSummaryByMonth)
    .slice(-3)
    .flatMap(([, expenses]) => expenses.map((expense) => expense.amount));

  const monthlyExpense = Math.abs(sum(last3MonthsExpenses)) / 3;

  const productWithLargestMaxBuyingPower =
    maxBy(productList, "maxBuyingPower") || {};

  const maxBorrowCapacity = getAvgBCAmountOfOneMonth(productList);
  const {
    maxBuyingPower = 0,
    monthlyRepaymentList = [],
  } = productWithLargestMaxBuyingPower;

  const monthlyRepayment = calculateMonthlyRepayment(monthlyRepaymentList);
  const monthlyRepaymentWithLoan = monthlyRepayment + monthlyExpense;

  return (
    <div className="bc-scenario-detail-result">
      <div className="bc-scenario-detail-result-group">
        <BorrowCapacityItem
          title="Max Loan Amount"
          value={formatNumberWithDollar(maxBorrowCapacity)}
        />
        <BorrowCapacityItem
          title="Real Buying Power"
          value={formatNumberWithDollar(maxBuyingPower)}
        />
        <BorrowCapacityItem
          title="Monthly Repayments"
          subTitle="(Real Buying Power)"
          value={formatNumberWithDollar(monthlyRepayment || 0)}
        />
        <BorrowCapacityItem
          title="Monthly Expenses With Loan"
          subTitle="(Real Buying Power)"
          value={formatNumberWithDollar(monthlyRepaymentWithLoan || 0)}
        />
      </div>
      <LoadingBackdrop isOpen={isLoading} content="Calculating Data..." />
    </div>
  );
};

BorrowCapacityItemGroup.propTypes = {};

export default BorrowCapacityItemGroup;
